import "../styles/hero-desktop.css";

export const HeroDesktop = () => {
	return (
		<section className="bg-echo-blue h-[730px] md:block hidden lg:px-32 relative">
			<div className="container h-full">
				<div className="md:flex justify-between items-center h-full">
					<div className="flex flex-col justify-center gap-6">
						<h1 className="space-y-8">
							<span className="px-6 py-4 text-3xl bg-white text-echo-blue hero-text">
								Zapojte se do pátrání
							</span>
							<div className="px-6 py-4 text-3xl bg-white text-echo-blue font-bold">
								po pohřešovaných osobách v ohrožení
							</div>
						</h1>
						<h2 className="text-white text-2xl">
							<span className="font-thin">Mobilní aplikace</span>{" "}
							<span className="font-bold">ECHO</span>
						</h2>
					</div>
					<img
						src="/img/landing-phone-desktop.webp"
						alt="hero-phones"
						className="z-10"
						height={625}
						width={450}
					/>
				</div>
				<img
					src="/img/landing-boy-girl-desktop.svg"
					alt="kids"
					className="absolute bottom-0 right-0 -z-0"
					height={899}
					width={662}
				/>
			</div>
		</section>
	);
};

export const HeroMobile = () => {
	return (
		<section className="bg-echo-blue flex -z-20 md:hidden flex-col min-h-[734px] justify-center">
			<div className="flex justify-center">
				<img src="/img/landing-phone-mobile.webp" alt="hero-phones" />
			</div>

			<div className="flex flex-col gap-6 -left-32 m-4">
				<h1 className="space-y-6">
					<span className="px-6 py-4 text-xl bg-white text-echo-blue">
						Zapojte se do pátrání
					</span>
					<div className="px-6 py-4 text-xl bg-white text-echo-blue font-bold max-w-[228px]">
						po pohřešovaných osobách v ohrožení
					</div>
				</h1>
				<h2 className="text-white">
					Mobilní aplikace <span className="font-bold">ECHO</span>
				</h2>
			</div>
			<img
				src="/img/landing-boy-girl-mobile.svg"
				alt="kids"
				className="absolute bottom-0 -z-0"
			/>
		</section>
	);
};
