export const Partners = () => {
	return (
		<div
			className="bg-echo-dark-gray py-12 md:px-56 flex flex-col items-center gap-12"
			id="partners"
		>
			<h2 className="text-white font-bold text-2xl lg:text-3xl">
				Partneři projektu
			</h2>
			<div className="grid grid-cols-2 grid-rows-3 md:hidden gap-12">
				<a href="https://www.mvcr.cz/" target="_blank" rel="noreferrer">
					<img
						src="/img/logos/mv.webp"
						alt="ministerstvo vnitra"
						width={128}
						height={30}
					/>
				</a>
				<a href="https://www.policie.cz/" target="_blank" rel="noreferrer">
					<img
						src="/img/logos/pcr.webp"
						alt="policie čr"
						width={128}
						height={30}
					/>
				</a>
				<a
					href="https://www.esfcr.cz/publicita-oplzz"
					target="_blank"
					rel="noreferrer"
				>
					<img
						src="/img/logos/eu.webp"
						alt="evropska unie"
						width={128}
						height={30}
					/>
				</a>
				<a
					href="https://www.nadacevodafone.cz/"
					target="_blank"
					rel="noreferrer"
				>
					<img
						src="/img/logos/laborator.webp"
						alt="laborator nadace vodafone"
						width={128}
						height={30}
					/>
				</a>
				<div className="flex justify-center">
					<a href="https://blaze.codes/" target="_blank" rel="noreferrer">
						<img
							src="/img/logos/blaze.webp"
							alt="blaze codes"
							width={128}
							height={30}
						/>
					</a>
				</div>
				<a
					href="https://www.nadacevodafone.cz/"
					target="_blank"
					rel="noreferrer"
				>
					<img
						src="/img/logos/vodafone.webp"
						alt="vodafone"
						width={128}
						height={30}
					/>
				</a>
			</div>

			<div className="md:grid grid-cols-3 grid-rows-2 hidden gap-12">
				<a href="https://www.mvcr.cz/" target="_blank" rel="noreferrer">
					<img
						src="/img/logos/mv.webp"
						alt="ministerstvo vnitra"
						width={232}
						height={55}
					/>
				</a>
				<a href="https://www.policie.cz/" target="_blank" rel="noreferrer">
					<img
						src="/img/logos/pcr.webp"
						alt="policie čr"
						width={232}
						height={63}
					/>
				</a>
				<a
					href="https://www.esfcr.cz/publicita-oplzz"
					target="_blank"
					rel="noreferrer"
				>
					<img
						src="/img/logos/eu.webp"
						alt="evropska unie"
						width={232}
						height={63}
					/>
				</a>
				<a
					href="https://www.nadacevodafone.cz/"
					target="_blank"
					rel="noreferrer"
				>
					<img
						src="/img/logos/laborator.webp"
						alt="laborator nadace vodafone"
						width={232}
						height={63}
					/>
				</a>
				<div className="flex justify-center">
					<a href="https://blaze.codes/" target="_blank" rel="noreferrer">
						<img
							src="/img/logos/blaze.webp"
							alt="blaze codes"
							width={216}
							height={246}
						/>
					</a>
				</div>
				<a href="https://blaze.codes/" target="_blank" rel="noreferrer">
					<img
						src="/img/logos/vodafone.webp"
						alt="vodafone"
						width={232}
						height={63}
					/>
				</a>
			</div>
		</div>
	);
};
