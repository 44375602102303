export const OurApp = () => {
	return (
		<section className="mt-20 lg:px-32">
			<div className="container" id="app">
				<div className="lg:flex flex-row-reverse justify-between">
					<div className="flex justify-center lg:justify-normal lg:block">
						<img
							src="/img/phones-template.webp"
							alt="echo aplikace"
							width={357}
							height={599}
						/>
					</div>
					<div className="space-y-8 font-thin lg:w-[480px] mt-10">
						<div className="space-y-4">
							<h2 className="md:text-3xl text-2xl font-bold">Aplikace</h2>
							<p className="font-thin text-echo-blue">Jak to funguje?</p>
						</div>
						<p>
							Aplikace ECHO je přímo propojena s policejní databází
							pohřešovaných osob, které jsou v bezprostředním ohrožení života
							nebo zdraví. V okamžiku, kdy Policie vloží do databáze nový
							případ, obdržíte notifikaci a v aplikaci najdete fotku a další
							relevantní informace. Můžete se tak ihned zapojit do pátrání –
							pokud budete mít o pohřešované osobě jakékoliv informace, můžete
							jedním kliknutím zavolat Policii ČR. Děkujeme, že pomáháte s námi.
						</p>
						<p>
							Aplikaci používají také policisté na svých služebních zařízeních v
							rámci Mobilní bezpečné platformy, strážnící městských a obecních
							policií a další členové IZS.
						</p>
						<div className="space-y-2">
							<h3 className="font-bold">
								Technologie užitá k vývoji aplikace:
							</h3>
							<p>
								Mobilní aplikace Echo, kterou pro nás vytvořili vývojáři full
								servisové digitální agentury{" "}
								<a href="https://blaze.codes/" target="_blank" rel="noreferrer">
									Blaze
								</a>
								, je napsána v Javascriptovém frameworku React Native, což nám
								umožňuje sdílení kódu mezi oběma platformami Android i iOS a tím
								snadnější i rychlejší vývoj pro obě platformy. Architektura
								serverové infrastruktury je navržena s využitím nejmodernějších
								postupů, jako Serverless, tak aby byla bezpečná, nenákladná,
								neomezeně škálovatelná a automaticky zvládla stále rostoucí
								počet mobilních uživatelů.
							</p>
						</div>
						<p className="font-bold">Aplikace ke stažení:</p>
					</div>
				</div>
				<div className="flex flex-col items-center gap-8 md:gap-32 md:flex-row md:justify-center mt-10 pb-20">
					<div className="flex flex-col items-center bg-white gap-6 px-12 py-6">
						<p className="font-bold">IOS</p>
						<a
							href="https://apps.apple.com/cz/app/echo/id1448240689?l=cs"
							target="_blank"
							rel="noreferrer"
						>
							<img
								src="/img/ios-app-store.webp"
								alt="app-store"
								width={160}
								height={192}
							/>
						</a>
					</div>
					<div className="flex flex-col items-center bg-white gap-6 px-12 py-6">
						<p className="font-bold">Android</p>
						<a
							href="https://play.google.com/store/apps/details?id=com.blueberry.echo"
							target="_blank"
							rel="noreferrer"
						>
							<img
								src="/img/google-play-store.webp"
								alt="google-play"
								width={160}
								height={192}
							/>
						</a>
					</div>
				</div>
			</div>
		</section>
	);
};
