export const MissingPeople = () => {
	return (
		<section className="mt-20 lg:px-32">
			<div className="container">
				<h3 className="font-bold lg:text-3xl text-2xl mb-8">
					Pohřešované osoby
				</h3>
				<div className="lg:flex justify-between font-thin">
					<div className="space-y-6 lg:space-y-8 lg:w-[480px]">
						<p>
							Do kategorie nejvíce ohrožených pohřešovaných osob z důvodu jejich
							zranitelnosti a bezbrannosti spadají hlavně děti a senioři. Proto
							jsou se zvláštním zřetelem řešeny především případy zmizení těchto
							osob, při kterých je bezprostředně ohroženo jejich zdraví nebo
							život. Nejen děti, ale i osoby pokročilého věku nejsou schopny v
							určitých situacích reagovat adekvátně a pátrání v mnoha případech
							nemá šťastný konec. Rychlý přenos informací jak mezi jednotlivými
							subjekty, tak i směrem k veřejnosti, je stěžejní. Bezprostředně po
							zmizení dítěte nebo seniora může veřejnost poskytnout relevantní
							informace, které napomohou k rychlému a úspěšnému vypátrání
							pohřešované osoby.
						</p>
						<p>
							V zájmu zvýšení rychlosti a efektivity pátrání spustilo
							Ministerstvo vnitra projekt Národního koordinačního mechanismu
							pátrání po pohřešovaných osobách v ohrožení. Jedná se o systém
							včasného varování v případech, kdy život nebo zdraví pohřešované
							osoby je v ohrožení a čas zde hraje zásadní roli. O aktivaci
							mechanismu a zařazení dítěte či seniora do něj rozhoduje Policie
							ČR na základě daných kritérií. Systém je založen na rychlém
							přenosu informací o pohřešované ohrožené osobě od policie k
							veřejnosti, zejména prostřednictvím médií. Rodinám pohřešovaných
							ohrožených osob je ze strany policejních psychologů poskytována
							profesionální psychologická pomoc, obrátit se mohou rovněž na
							linky důvěry a neziskové organizace poskytující pomoc v obtížných
							životních situacích. Kontaktní údaje by jim měla předat policie.
						</p>
					</div>
					<div className="space-y-6 lg:space-y-8 lg:w-[480px]">
						<p>
							Pomoci při pátrání po pohřešovaném dítěti či senioru v ohrožení
							může každý z nás. Aplikace ECHO je jedinou mobilní aplikací, která
							napomáhá Policii ČR při pátrání po těchto nejzranitelnějších
							osobách. V případě, že je spuštěn Národní koordinační mechanizmus
							pátrání po pohřešované osobě v ohrožení, objeví se v aplikaci
							fotografie a též bližší informace o ohrožené osobě.
						</p>
						<p>
							Aplikace ECHO je jedinou mobilní aplikací, která napomáhá Policii
							ČR při pátrání po těchto nejzranitelnějších osobách. V případě, že
							je spuštěn Národní koordinační mechanizmus pátrání po pohřešované
							osobě v ohrožení, objeví se současně fotografie a informace o
							ohrožené osobě i v této aplikaci.
						</p>
						<p>
							Tento systém včasného varování fungující v některých evropských i
							mimoevropských státech již pomohl zachránit mnohé životy. Pro
							stejný účel byl vytvořen také v ČR. Informujte o tomto projektu a
							o způsobu, jak se do něj zapojit členy své rodiny, přátele,
							kolegy,… Čím více lidí se dozví o vyhlášeném pátrání, tím větší je
							šance, že pohřešované dítě nebo senior budou nalezeni živý a
							zdravý.
						</p>
					</div>
				</div>
			</div>
		</section>
	);
};
