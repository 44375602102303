import type { MetaFunction } from "@remix-run/node";
import { AboutProject } from "~/components/about-project";
import { AboutUs } from "~/components/about-us";
import { Contact } from "~/components/contact";
import { Documents } from "~/components/documents";
import { HeroDesktop, HeroMobile } from "~/components/hero-section";
import { MissingPeople } from "~/components/missing-people";
import { OurApp } from "~/components/our-app";
import { Partners } from "~/components/partners";

export const meta: MetaFunction = () => {
	return [
		{ title: "Aplikace Echo" },
		{
			name: "description",
			content: "Mobilní aplikace echo pro pohřešované děti.",
		},
		{
			name: "keywords",
			content: "pohřešované, echo, policie, patrani",
		},
		{
			property: "og:title",
			content: "Aplikace Echo",
		},
		{
			property: "og:description",
			content: "Mobilní aplikace echo pro pohřešované děti.",
		},
		{ property: "og:type", content: "website" },
		{ property: "og:url", content: "https://www.aplikaceecho.cz/" },
	];
};

export default function Index() {
	return (
		<div>
			<HeroDesktop />
			<HeroMobile />
			<AboutProject />
			<MissingPeople />
			<AboutUs />
			<OurApp />
			<Documents />
			<Partners />
			<Contact />
		</div>
	);
}
