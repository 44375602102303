export const AboutUs = () => {
	return (
		<section className="lg:px-32 mt-20">
			<div
				className="flex flex-col items-center lg:flex-row lg:justify-between  container"
				id="aboutus"
			>
				<img
					src="/img/saved-kid.webp"
					alt="policie a dítě"
					width={480}
					height={480}
					className="my-8"
				/>
				<div className="space-y-6 lg:w-[480px]">
					<h2 className="text-2xl md:text-3xl font-bold">O nás</h2>
					<p className="font-thin">
						S nápadem na využití moderních technologií pro záchranu životů
						přišla dvojice Martin Kaiser a Petr Bureš. Založili neziskovou
						organizaci Aplikace ECHO, z.s, která projekt realizovala v rámci
						programu Laboratoře Nadace Vodafone. V současnosti jsme pětičlenný
						tým, kromě zakladatelů se na projektu podílí ještě další tři
						kolegyně.
					</p>
				</div>
			</div>
		</section>
	);
};
