export const Contact = () => {
	return (
		<section className="my-20 lg:px-32">
			<div className="container" id="contact">
				<div className="text-center space-y-4 mb-8">
					<h2 className="font-bold text-2xl lg:text-3xl">Zajímá vás to?</h2>
					<p className="font-thin text-echo-blue">
						Moc rádi odpovíme na vaše dotazy
					</p>
				</div>
				<div className="flex flex-col gap-y-8 md:flex-row md:gap-x-8 font-thin mt-12">
					<div className="flex flex-col items-center gap-3 md:flex-1">
						<div className="space-y-2">
							<div className="flex justify-center">
								<img
									src="/img/icons/email.svg"
									width={40}
									height={40}
									alt="email"
									className="md:w-14 md:h-14"
								/>
							</div>
							<p className="font-bold text-center">Email</p>
						</div>
						<u className="text-echo-blue">
							<a href="mailto:info@aplikaceecho.cz">info@aplikaceecho.cz</a>
						</u>
					</div>
					<div className="flex flex-col items-center gap-3 md:flex-1">
						<div className="space-y-2">
							<div className="flex justify-center">
								<img
									src="/img/icons/facebook.svg"
									width={40}
									height={40}
									alt="email"
									className="md:w-14 md:h-14"
								/>
							</div>
							<p className="font-bold text-center">Facebook</p>
						</div>
						<u className="text-echo-blue">
							<a
								href="https://www.facebook.com/AplikaceEcho"
								target="_blank"
								rel="noreferrer"
							>
								https://www.facebook.com/AplikaceEcho/
							</a>
						</u>
					</div>
					<div className="flex flex-col items-center gap-3 md:flex-1">
						<div className="space-y-2">
							<div className="flex justify-center">
								<img
									src="/img/icons/home.svg"
									width={40}
									height={40}
									alt="udaje"
									className="md:w-14 md:h-14"
								/>
							</div>
							<p className="font-bold text-center">Údaje</p>
						</div>
						<div className="text-echo-blue text-center">
							<p>Aplikace Echo, z.s.,</p>
							<p>IČ 05938619</p>
							<p>transparentní účet u Raiffeisenbank</p>
							<p>3213215500/5500</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
