import { Link } from "@remix-run/react";

export const AboutProject = () => {
	return (
		<section className="mt-10 md:mt-20 lg:px-32" id="project">
			<div className="container md:flex md:justify-between gap-32">
				<div className="md:w-[480px]">
					<h2 className="text-2xl md:text-3xl font-semibold my-4">
						O projektu
					</h2>
					<p className="text-echo-blue mb-6">
						Mobilní aplikace ECHO přispívá k rychlému a úspěšnému nalezení
						pohřešovaných dětí a seniorů
					</p>
					<div className="space-y-6 font-thin">
						<p>
							Aplikace informuje veřejnost o pohřešovaných dětech a seniorech,
							kteří jsou v bezprostředním ohrožení života nebo zdraví, nikoliv o
							všech pohřešovaných osobách (ročně několik tisíc případů). O tom,
							které pohřešované osoby se v aplikaci ECHO objeví, rozhoduje
							výhradně Policie ČR. Aplikace má za cíl pomoci výhradně v těchto
							nejzávažnějších případech, kterých bývá ročně několik desítek.
						</p>
						<p>
							Aplikace ECHO vznikla v úzké spolupráci s Policií ČR a
							Ministerstvem vnitra - je přímo napojena na policejní databázi
							pohřešovaných osob v ohrožení. V okamžiku, kdy Policie vloží do
							databáze nový případ, obdrží všichni uživatelé notifikaci a bližší
							informace k danému případu. První hodiny od zmizení jsou totiž
							zásadní a s každou další minutou šance na nalezení výrazně klesá.
							V aplikaci ECHO jsou k dispozici vždy aktuální informace.
							Uživatelé mohou jedním stisknutím zavolat Policii ČR a předat
							relevantní informace.
						</p>
						<p>
							Zapojte se také. Stáhněte si i Vy aplikaci ECHO a dívejte se kolem
							sebe.
						</p>
						<p>
							Oficiálními partnery Aplikace ECHO, z.s. jsou:{" "}
							<u>
								<a
									href="https://www.policie.cz/"
									target="_blank"
									rel="noreferrer"
								>
									Policie ČR
								</a>
							</u>
							,{" "}
							<u>
								<a href="https://www.mvcr.cz/" target="_blank" rel="noreferrer">
									Ministerstvo vnitra ČR{" "}
								</a>
							</u>{" "}
							a{" "}
							<u>
								<a
									href="https://www.nadacevodafone.cz/"
									target="_blank"
									rel="noreferrer"
								>
									Nadace Vodafone
								</a>
							</u>
						</p>
					</div>
					<div className="mt-20">
						<h3 className="font-bold text-xl mb-4">Ke zhlédnutí</h3>
						<div className="space-y-2 text-echo-blue underline mb-20">
							<a
								href="https://www.youtube.com/watch?v=1wyKnq9_XJs&list=PL4Oh_tZV5kENb4npp383rS9-eppKN9UYe&index=3"
								target="_blank"
								rel="noreferrer"
								className="flex gap-2"
							>
								Produktové video aplikace ECHO
								<img
									src="img/icons/polygon.svg"
									alt="Produktové video aplikace ECHO"
								/>
							</a>
							<a
								href="https://www.youtube.com/watch?v=Hvcm70Asn8I"
								target="_blank"
								rel="noreferrer"
								className="flex gap-2"
							>
								Jolana Voldánová pro aplikaci ECHO
								<img
									src="img/icons/polygon.svg"
									alt="Produktové video aplikace ECHO"
								/>
							</a>
							<a
								href="https://www.youtube.com/watch?v=0pvk-F-IGKY"
								target="_blank"
								rel="noreferrer"
								className="flex gap-2"
							>
								plk. Rybár pro aplikaci ECHO
								<img
									src="img/icons/polygon.svg"
									alt="Produktové video aplikace ECHO"
								/>
							</a>
						</div>
					</div>
				</div>
				<div className="flex flex-col items-center gap-10 md:mt-20">
					<div className="space-y-8 max-w-[480px]">
						<div className="flex px-8 py-6 gap-6  bg-white">
							<img
								src="img/icons/police.svg"
								width={56}
								height={56}
								alt="policie"
							/>
							<div className="flex flex-col gap-2">
								<p className="font-bold">Policie ČR každoročně pátrá</p>
								<p className="font-thin">
									po desítkách dětí a seniorů, kteří jsou přímo ohroženi na
									zdraví nebo na životě.
								</p>
							</div>
						</div>
						<div className="flex px-8 py-6 gap-6 bg-white">
							<img
								src="img/icons/clock.svg"
								width={56}
								height={56}
								alt="hodiny"
							/>
							<div className="flex flex-col gap-2">
								<p className="font-bold">První hodiny</p>
								<p className="font-thin">
									od zmizení jsou klíčové a s každou další minutou šance na
									nalezení výrazně klesá.
								</p>
							</div>
						</div>
						<div className="flex px-8 py-6 gap-6 bg-white">
							<img
								src="img/icons/phone.svg"
								width={56}
								height={56}
								alt="phone"
							/>
							<div className="flex flex-col gap-2">
								<p className="font-bold">Mobilní aplikace ECHO</p>
								<p className="font-thin">
									zapojí do pátrání širokou veřejnost a tím zvýší šanci na
									včasné nalezení osob v ohrožení.
								</p>
							</div>
						</div>
					</div>
					<Link to="#app">
						<button
							type="button"
							className="bg-echo-blue text-white px-8 py-4 font-semibold"
						>
							Stáhnout aplikaci
						</button>
					</Link>
				</div>
			</div>
		</section>
	);
};
